.EuroNav__nav-shadow {
  /* box-shadow: 0px 1px 6px rgba(150, 150, 150, 0.3); */
}

.EuroHeadline__header-headline {
  margin-top: 1em;
  font-size: 1.5rem;
  white-space: nowrap;
}

.EuroHeadline {
  height: 8rem;
}

.EuroHeadline__paragraph-headline {
  font-size: 1rem;
  margin-top: 2em;
  font-weight: 300;
  display: inline;
}

.large-vi {
  height: 26px;
  width: inherit;
}
.large-vi-active {
  height: 26px;
  width: inherit;
  background-color: #79c000;
  border-radius: 50%;
  border: #79c000 2px solid;
}

.EuroHeadline__headline-and-paragraph {
  align-items: center;
}

.teams-box {
  width: 76vw;
  border: #f1f1f1 solid 2px;
  border-radius: 20px;
  background-color: white;
}
.stepDiv {
  border-bottom: 1px solid #d5d5d5;
}
.stepDivClick:first-child {
  background: linear-gradient(91.47deg, #508ce3 0.5%, #4d678d 100%);
  border-radius: 20px 20px 0 0;
}
.stepDivClick {
  background: linear-gradient(91.47deg, #508ce3 0.5%, #4d678d 100%);
}
.stepDivClick:last-child {
  background: linear-gradient(91.47deg, #508ce3 0.5%, #4d678d 100%);
  border-radius: 0 0 20px 20px;
}
.stepDiv:last-child {
  border-bottom: none;
}
.knock-out-match-box {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  width: 70vw;
  border: #ebebeb solid 1px;
  background-color: white;
}
.row-empty {
  background-color: #ebebeb;
  height: 50%;
  display: flex;
  justify-content: left;
}
.team-box__details {
  margin-left: auto;
  font-weight: 500;
  color: blue;
  font-size: 0.9rem;
}

.team-box__group-name {
  margin-left: 1em;
  font-weight: 700;
}
.small-flag {
  width: 1.5rem;
  height: 1rem;
}

.TournamentBody {
  /* position: fixed; */
  height: 140rem;
  width: 100vw;
  /* overflow: auto; */
  background-color: #fafafa;
  padding-bottom: 3rem;
}

.slick-slider,
.slick-track,
.slick-track {
  height: 100%;
}

.sliderContainer {
  /* height: 135rem; */
}

.teams-box-knockout {
  height: 100%;
  padding-top: 5rem;
}

.team-table-row {
  border-top: #f1f1f1 solid 2px;
}

.ranking-box {
  border: #f1f1f1 solid 2px;
  border-radius: 15px;
  font-weight: 500;
  justify-content: center;
}

.ranking-btn-1,
.ranking-btn-2 {
  border-right: #f1f1f1 solid 2px;
}

.ranking-btn-1 {
  border-radius: 15px 0 0 15px;
}

.ranking-btn-3 {
  border-radius: 0 15px 15px 0;
}

.circle-vi {
  border: #4f76b0 solid 1px;
  border-radius: 50px;
  height: 1.2rem;
  width: 1.2rem;
}
.name-team {
  padding-left: 5%;
  white-space: nowrap;
}

.container-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.image-final {
  margin-left: 7em;
  margin-bottom: 2em;
}

.groups-flex-container {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: space-between;
  transition: height ease-in 500ms;
}

.matches-flex-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  transition: height ease 500ms, margin-left ease 500ms;
  padding-bottom: 3rem;
}

.final-match-container {
  transition: height ease 500ms, margin-left ease 500ms;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  transition: all ease 500ms;
  width: 100%;
  min-height: 13rem;
  position: relative;
}

.offsetMatches {
  margin-left: -5rem;
}

.offsetMatches_not_filled_groups {
  margin-left: 0em;
}

.offsetFinalMatch {
  margin-left: 0rem;
  padding-bottom: 0rem;
  margin-top: 26.5rem;
  min-height: 6rem;
}

.offsetFinalMatch_not_filled_groups {
  margin-left: 0rem;
  padding-bottom: -1rem;
  margin-top: 7.5rem;
  min-height: 43rem;
}

.detailsImage {
  transition: opacity 500ms;
}

.offsetMatches .detailsImage,
.offsetFinalMatch .detailsImage {
  opacity: 0;
}

.button,
.claim-bet {
  background-color: #e7e7e7; /* Green */
  border: none;
  border-radius: 20px;
  background: linear-gradient(91.59deg, #82c413 4.06%, #a5f126 103.33%);
  color: white;
  box-shadow: 1px 1px 4px rgba(141, 141, 141, 0.25);
  border-radius: 20px;
  padding: 0.6em 0em;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 1rem;
  margin: 5px auto;
  font-weight: bold;
  width: 10em;
  height: 46px;
  margin-bottom: 20px;
  cursor: pointer;
}

.claim-bet {
  padding: 0.6em 0em;
  width: 12em;
}

.claim-bet-text {
  font-size: 0.8em;
  margin-bottom: 0;
}

.form-select-style {
  width: calc(100% - 1rem);
  padding-left: 0.3rem;
  background-size: 0.8rem !important;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding-right: 2rem;
}

.form-select-style > option {
  color: black;
}

.checkedTeam .form-select-style {
  color: white;
}

.form-select-style.unselected {
  width: auto;
}

.btn-edit-enabled {
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  background: linear-gradient(100.46deg, #224881 6.42%, #4d678d 100%);
  border: 1px solid #ffffff;
  color: white;
  border-radius: 50px;
}

.btn-edit-disabled {
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  background: #cfcfcf;
  border: 1px solid #ffffff;
  color: white;
  border-radius: 50px;
}

.submitContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
}

.finalFlagImg {
  height: 60vw;
  max-height: 40rem;
}

.slick-slide {
  padding-bottom: 5rem;
}

a.textLink,
a.textLink:link,
a.textLink:hover,
a.textLink:visited {
  color: #4f76b0;
  text-decoration: none;
}

.makeStyles-modal-5 {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 30rem;
}

.font-face-Myriad {
  font-family: "Myriad";
  font-weight: bold;
  color: #5cb5c9;
}

.backroundImg {
  width: 100vw;
  height: 100vh;
}

/* animation */
