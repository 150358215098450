body {
  margin: 0;
  color: #353d49 !important;
  /* min-height: 100vh !important; */
  background-color: #fafafa;
  height: 140rem;
  margin-top: 5rem;
}

@media screen and (max-device-width: 480px) {
  body {
    -webkit-text-size-adjust: none;
  }
}

@media screen and (max-device-height: 900px) {
  body {
    /* min-height: 900px; */
  }
}

.App {
  /* min-height: 70vh; */
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.round {
  font-size: 14px;
  color: #353d49;
  line-height: 17px;

  margin-bottom: 0;
}

/* header section */

.nav-shadow {
  box-shadow: 0px 1px 6px rgba(150, 150, 150, 0.3);
  background-color: #333f48;
}

.header-container {
  box-shadow: 0px 1px 6px rgba(150, 150, 150, 0.3);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 0;
}

.predictions {
  font-weight: 500;
  line-height: 20px;
  color: #353d49;
  font-size: 1.3em;
}

/* matches section*/
.mtches-body {
  /* padding: env(safe-area-inset-top, 20px); */
  margin-top: 15em;
}
.team-container {
  margin-top: 0.8em;
}

.match-box {
  border: 1px solid rgba(236, 236, 236, 0.5);
  min-width: 100%;
  min-height: 100%;
  background-color: white;

  background-size: 100%;

  box-sizing: border-box;
  border-radius: 10px;
  padding: 0.5em;
}

/* accumulator match section */

.small-shirt {
  height: 18px;
  width: 16px;
}

.accum-dollar-bet {
  margin-top: 240px;
  background-color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

@media all and (min-width: 425px) {
  .accum-dollar-bet {
    margin-top: 270px;
  }
}
@media all and (min-width: 560px) {
  .accum-dollar-bet {
    margin-top: 290px;
  }
}
@media all and (min-width: 680px) {
  .accum-dollar-bet {
    margin-top: 310px;
  }
}
@media all and (min-width: 768px) {
  .accum-dollar-bet {
    margin-top: 330px;
  }
}
@media all and (min-width: 1100px) {
  .accum-dollar-bet {
    margin-top: 340px;
  }
}

@media all and (min-width: 1400px) {
  .accum-dollar-bet {
    margin-top: 370px;
  }
}
@media all and (min-width: 1400px) and (max-width: 2570px) {
  .accum-dollar-bet {
    margin-top: 500px;
  }
}

.accum-dollar-bet-no-banner {
  margin-top: 140px;
  background-color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.accum-matches-box-headers {
  background-color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7f8287;
}

.green-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #79c000;
}

.accum-matches-box {
  background-color: #fff;
  font-size: 12px;
  line-height: 14px;
}

.accum-matches-box-numbers {
  position: relative;
  top: 10px;

  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
.vi-box {
  margin-right: 3em;
}

.vi {
  position: absolute;
  margin-left: 9px;
  margin-top: 8px;
}
.viCircle {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
}

.accum-padd {
  margin: 0;
}

.banner {
  width: 60%;
  border-radius: 10px;
}

.header-banner {
  background-color: #ececec;
}

@media all and (max-width: 768px) {
  .banner {
    width: 95%;
  }
}
/* interactive predictions */

.prediction-box {
  width: 2em;
  padding-top: 0.5em;
}

.prediction-num-wrapper {
  position: relative;
  text-align: center;
}

.prediction-num {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
}

.arrows-box {
  position: relative;
  height: 2em;
  width: 2em;
  margin: 0.3em 0;
  background-image: url("./images/match-box/activeBTN.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  background-color: transparent;
  outline: none;
}

.arrows-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.team-img {
  margin: 0 auto;
  display: block;
}

/* end of interactive predictions */

/* --vs image and text centering-- */

.vs-wrapper {
  background-image: url("./images/match-box/vsDecoration.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 80px;
}
.vs {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  color: #353d49;
  margin-top: 5px;
}

/* --end of vs image and text centering-- */

.match-time {
  font-size: 14px;
  line-height: 17px;
  color: #353d49;
  margin-bottom: 0.5em;
}

.date {
  font-size: 14px;
  font-weight: 400;
  color: rgba(53, 61, 73, 0.6);
}

.team-name {
  color: #353d49;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

/* end of matches section*/

/* bottom of the page */

.footer {
  background-color: #fff;
  height: 4rem;
  box-shadow: 0px 1px 6px rgba(150, 150, 150, 0.3);
}

.footer-img {
  padding-top: 10px;
  width: 30px;
  height: 34px;
}

.forgot-password-footer-text {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 57.47%);
  font-weight: bold;
  font-size: 0.8em;
  padding: 1em;
  color: #ffffff;
}

.forgot-password-footer-text:hover {
  color: #ffffff;
  text-decoration: none;
}

.back-to-login-text {
  margin-left: 20px;
}

.email-sent-text {
  float: right;
  margin-right: 20px;
}

.vi-email-sent {
  margin-left: 18.4em;
}

/* modal styling */

.modal-body {
  padding: 0 !important;
}

.modal-content {
  display: block;
  margin: 0 auto;
  background: linear-gradient(161.5deg, #adff22 9.14%, #7ebc15 83.96%);
  padding: 20px 0px;
  padding-bottom: 0;
  min-height: 300px;
  border-radius: 20px !important;
  border: none !important;
  width: 305px !important;
}
.predictions-modal {
  margin: 0 auto;
}

.modal-vi-div {
  position: relative;
  margin-left: 15px;
}

.circle-img {
  position: absolute;
  width: 66px;
  height: 66px;
  margin: 0 2em;
}
.vi-img {
  position: absolute;
  margin-top: 20px;
  margin-left: 47px;
  width: 35px;
  height: 27px;
}

.modal {
  margin: 20vh auto 0px auto;
  border-radius: 20px;
}

.success {
  margin-top: 80px;
}
.success-text {
  float: right;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
}
.trophy-img {
  float: left;
  margin-top: 5px;
  margin-right: 10px;
}

.prediction-submitted {
  font-size: 14px;
  line-height: 17px;
  color: #353d49;
  text-align: center;
  margin-left: 10px;
}

.next-link-div {
  text-align: center;
  margin-top: 25px;
  background-color: #8ec72d;
  padding: 25px;
  border-radius: 0px 0px 20px 20px;
}

.next-link {
  color: white;

  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.next-link:hover {
  text-decoration: none;
  color: white;
}
.next-link:active {
  color: white;
}

.next-link-arrow {
  margin-left: 10px;
  margin-bottom: 2px;
}
/* end modal */

.spinner {
  margin-left: 45%;
  margin-top: 400px;
}

/*signin signup */

/* switch button */
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #79c000 !important;
}
/* // */

.signup-header {
  background-color: rgba(53, 61, 73, 1);
  text-align: center;
}

.signup-logo {
  padding-top: 3em;
}

.signup-text {
  color: white;
  padding-bottom: 0.5em;
  padding-top: 0.7em;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
}

.signup-decoration-line {
  border-top: 2px solid rgb(159, 172, 190);
  margin: 15px 0;
}

.text-field-div fieldset {
  border-radius: 20px;
}

/* tabs material ui */

/* selectet tab predictions */
.matches-specific .MuiTab-textColorPrimary.Mui-selected,
.MuiTab-textColorPrimary {
  color: #79c100 !important;
  font-weight: 550 !important;
  font-size: 0.9em !important;
  font-family: sans-serif !important;
  text-decoration: none !important;
}

/* non selected tab predictions */
.matches-specific .MuiTab-textColorPrimary {
  color: white !important;
}

/* selectet tab accumulator */
.accumulator-specific .MuiTab-textColorPrimary.Mui-selected,
.MuiTab-textColorPrimary {
  color: #353d49 !important;
  font-weight: 550 !important;
  font-size: 0.9em !important;
  font-family: sans-serif !important;
  text-decoration: none !important;
}

/* non selected tab accumulator */
.accumulator-specific .MuiTab-textColorPrimary {
  color: rgba(53, 61, 73, 0.6) !important;
}

.tabs-container,
.accumulator-tabs {
  border-bottom: solid rgb(224, 224, 224) 1px;
  background-color: #333f48;
}

.accumulator-tabs {
  background-color: white;
  color: black;
  border-radius: 10px;
}

.MuiTab-wrapper {
  text-transform: none;
}

.footer-link:hover {
  text-decoration: none !important;
}
